export default function userReducer(state=null, action) {
  switch (action.type) {
    case 'auth/login':
      return {token: action.token};
    case 'auth/logout':
      return null;
    case 'user/load':
      return {...(state || {}), ...action.user};    
    default:
      return state;
  }
}
