import { IonRouterLink, IonText } from '@ionic/react';
import LoginForm from '@/components/LoginForm';
import NarrowPage from '@/components/NarrowPage';

const LoginPage = () => {
  return (
    <NarrowPage>
      <IonText class="ion-text-center">
        <h1>Login</h1>
      </IonText>
      <LoginForm/>
      <div className="ion-text-center ion-padding ion-margin-vertical">
        <IonRouterLink routerLink="/signup">Sign Up</IonRouterLink>
      </div>
      <div className="ion-text-center ion-padding ion-margin-vertical">
        <IonRouterLink routerLink="/" routerDirection="back">Home</IonRouterLink>
      </div>
    </NarrowPage>
  );
}

export default LoginPage;
