import { useSelector, useDispatch } from 'react-redux';
import { IonItem, IonIcon, IonLabel } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { logout } from '@/actions/user';

import { logOutOutline } from 'ionicons/icons';
import NarrowPage from '@/components/NarrowPage';

const UserHomePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((store) => store.user);

  const logOut = async () => {
    await dispatch(logout());
    history.push('/');
  };

  return (
    <NarrowPage>
      Hello, {user.name}. UserHomePage Coming Soon!
        <IonItem detail={false} button onClick={logOut}>
          <IonIcon aria-hidden="true" slot="start" icon={logOutOutline} />
          <IonLabel>Log Out</IonLabel>
        </IonItem>
    </NarrowPage>
  );
}

export default UserHomePage;
