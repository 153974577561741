export function signUp({email, password, name}) {
  return async (dispatch, getState, api) => {
    const {data} = await api.signUp({email, password, name, agreedToTermsAt: new Date()});
    if (data.user) {
      await dispatch(login({email, password}));
    }
    return data;
  }
}

export function login({email, password}) {
  return async (dispatch, getState, api) => {
    const {data} = await api.login({email, password});
    if (data.token) {
      dispatch({type: 'auth/login', token: data.token});
      return await dispatch(getUser());
    }
    return data;
  }
}

export function logout() {
  return {type: 'auth/logout'};
}

export function getUser() {
  return async (dispatch, _getState, api) => {
    const {data} = await api.getUser();
    dispatch({type: 'user/load', user: data.user});
    return data.user;
  }
}
