export function TermsLink({children}) {
  return (
    <a href="https://example.com/terms-and-conditions"
      target="_blank"
      style={{pointerEvents: 'all'}}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        window.open("https://example.com/terms-and-conditions", "_blank");
      }}
    >
      {children || 'terms and conditions'}
    </a>
  );
};

export function PrivacyLink() {
  return (
    <a href="https://example.com/privacy" target="_blank">
      Privacy Policy
    </a>
  );
};
