import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { IonSpinner } from '@ionic/react';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import mixpanel from 'mixpanel-browser';

import ErrorPage from '@/pages/ErrorPage';
import initStore from './store';
import App from './App';

defineCustomElements(window);

const rollbarConfig = {
  enabled: !!import.meta.env.VITE_ROLLBAR_CLIENT_TOKEN,
  accessToken: import.meta.env.VITE_ROLLBAR_CLIENT_TOKEN,
  environment: import.meta.env.VITE_ENV ?? 'production',
  version: import.meta.env.VITE_GIT_COMMIT_HASH
};

mixpanel.init(import.meta.env.VITE_MIXPANEL_KEY || "Disable", {ignore_dnt: true});
if (!import.meta.env.VITE_MIXPANEL_KEY) mixpanel.disable();

const container = document.getElementById('root');
const root = createRoot(container);

const {store, persistor, cable} = initStore();

root.render(
  <React.StrictMode>
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary fallbackUI={ErrorPage}>
        <Provider store={store}>
          <PersistGate loading={<IonSpinner className="app-loading"/>} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </RollbarProvider>
  </React.StrictMode>
);

const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register('service_worker.js', {scope: './'});
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
};
registerServiceWorker();
