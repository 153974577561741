import {IonContent, IonPage, IonImg, IonRouterLink, IonButton} from '@ionic/react';
import {isDarkMode} from '@/helpers/platform';
import GetStarted from '@/components/GetStarted';
import LOGO from '@/assets/logo-splash-md-light-transparent.png';
import LOGO_DARK from '@/assets/logo-splash-md-dark-transparent.png';
import './Home.css';

const Home = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="ion-padding container-small ion-margin-bottom">
          <IonImg src={isDarkMode() ? LOGO_DARK : LOGO} alt="Panovare"/>
          <GetStarted />
          <IonButton routerLink="/signup" expand="block">Sign Up</IonButton>
          <IonButton routerLink="/login" expand="block" fill="outline">Log In</IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
