import { Redirect, Route, useHistory } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { useSelector } from 'react-redux'
import { createBrowserHistory as createHistory } from 'history';

import Home from './pages/Home';
import SignUpPage from './pages/SignUpPage';
import LoginPage from './pages/LoginPage';
import UserHomePage from './pages/UserHomePage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/main.scss';

import AppUrlListener from '@/components/AppUrlListener'

const history = createHistory();

setupIonicReact();

const UnauthenticatedApp = () => (
  <IonApp>
    <IonReactRouter history={history}>
      <AppUrlListener/>
      <IonRouterOutlet>
        <Route exact path="/home" component={Home}/>
        <Route exact path="/" component={() => <Redirect to="/home"/>}/>
        <Route path="/signup" exact component={SignUpPage}/>
        <Route path="/login" exact component={LoginPage}/>
        <Route component={() => <Redirect to="/home"/>}/>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

const UserApp = () => (
  <IonApp>
    <IonReactRouter history={history}>
      <AppUrlListener/>
      <IonRouterOutlet>
        <Route exact path="/home" component={UserHomePage}/>
        <Route exact path="/" component={() => <Redirect to="/home"/>}/>
        <Route component={() => <Redirect to="/home"/>}/>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);


const App = () => {
  const user = useSelector((store) => store.user);

  if (user) {
    return <UserApp/>;
  } else {
    return <UnauthenticatedApp/>;
  }
};

export default App;
