import { IonRouterLink, IonText } from '@ionic/react';
import SignUpForm from '@/components/SignUpForm';
import NarrowPage from '@/components/NarrowPage';
import { PrivacyLink } from '@/components/links';

const SignUpPage = () => {
  return (
    <NarrowPage>
      <IonText class="ion-text-center">
        <h1>Welcome!</h1>
        <h1>Create Your Profile</h1>
      </IonText>
      <SignUpForm/>
      <div className="ion-text-center ion-padding ion-margin-vertical">
        <IonRouterLink routerLink="/login">Log In</IonRouterLink>
      </div>
      <div className="ion-text-center ion-padding ion-margin-vertical">
        <IonRouterLink routerLink="/" routerDirection="back">Home</IonRouterLink>
      </div>
      <div className="ion-text-center ion-padding ion-margin-vertical">
        <PrivacyLink/>
      </div>
    </NarrowPage>
  );
};

export default SignUpPage;
