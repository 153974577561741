import {useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { useRollbarPerson } from '@rollbar/react';


export default function AppUrlListener() {
  const location = useLocation();

  const user = useSelector((store) => store.user);

  useEffect(() => {
    mixpanel.identify(user?.email);
  }, [user?.email]);


  useEffect(() => {
    mixpanel.track('Page visit');
  }, [location]);

  useRollbarPerson(user);
};
