import { IonContent, IonPage } from '@ionic/react';
import Menu from '@/components/Menu';

const NarrowPage = ({children}) => {
  return (
    <IonPage className="narrow-page">
      <Menu />
      <IonContent fullscreen>
        <div className="ion-padding container-small ion-margin-bottom">
          {children}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NarrowPage;
