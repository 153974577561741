import _ from 'lodash';
import axios from 'axios';

const METHODS = ['get', 'post', 'patch', 'delete'];
const snakeKeys = (obj) => _.mapKeys(obj, (v, k) => _.snakeCase(k));

export default function createAPI(getToken) {
  const base = () => {
    const api = axios.create({
      baseURL: import.meta.env.VITE_API ?? '/api/v1/',
      responseType: 'json',
      headers: {
       'Accept': 'application/json',
       'Authorization': `Bearer ${getToken()}`
     }
    });
    return _.pick(api, ...METHODS);
  }

  const [get, post, patch, delete_] = METHODS.map((method) => (...args) => base()[method](...args));

  return {
    base,

    login: ({email, password}) => post('auth/login', {email, password}),
    signUp: (user) => post('user', {user: snakeKeys(user)}),
    getUser: () => get('user'),
  };
}
