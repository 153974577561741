import { IonButton, IonButtons, IonImg, IonThumbnail, IonTitle, IonToolbar } from '@ionic/react';
import { isDarkMode } from '@/helpers/platform';
import LOGO from '@/assets/logo-splash-md-light-transparent.png';
import LOGO_DARK from '@/assets/logo-splash-md-dark-transparent.png';

const Menu = () => {
  return (
    <IonToolbar>
      <IonThumbnail slot="start">
        <IonImg src={isDarkMode() ? LOGO_DARK : LOGO} alt="Panovare"/>
      </IonThumbnail>
      <IonTitle>Panovare</IonTitle>
      <IonButtons slot="primary">
        <IonButton routerLink="/" expand="block" fill="clear">Home/TBD</IonButton>
      </IonButtons>
    </IonToolbar>
  );
};

export default Menu;
