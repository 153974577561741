import _ from 'lodash';
import { createStore, compose, applyMiddleware } from 'redux';
import {withExtraArgument} from 'redux-thunk';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducers';
import createAPI from './api';

const persistConfig = {
  key: 'panovare',
  version: 0,
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function initStore() {
  let store;
  const api = createAPI(() => _.get(store?.getState(), 'user.token'));
  store = createStore(
    persistedReducer,
    compose(applyMiddleware(withExtraArgument(api)))
  );
  const persistor = persistStore(store);
  return {store, persistor};
}
