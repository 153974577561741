import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IonCheckbox, IonButton, IonItem, IonSpinner } from '@ionic/react';

import { signUp } from '@/actions/user';
import { preventDefault } from '@/helpers/events';
import ValidatedInput from '@/components/ValidatedInput';
import PasswordInput from '@/components/PasswordInput';
import { TermsLink } from '@/components/links';

const SignUpForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const [agree, setAgree] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  const emailValid = email.includes('@');
  const passwordValid = password.length >= 8;
  const nameValid = name.length >= 3;
  const valid = _.every([emailValid, passwordValid, nameValid, agree]);

  const submitSignUp = async () => {
    setErrorMessage(null);
    if (!valid) return;

    setLoading(true);
    try {
      const result = await dispatch(signUp({email, password, name}));
      history.push('/')
    } catch (e) {
      setErrorMessage(_.get(e, 'response.data.error', e.message));
    }
    setLoading(false);
  };

  return (
    <form onSubmit={preventDefault(submitSignUp)}>
      {errorMessage && <IonItem color="danger">{errorMessage}</IonItem>}
      <IonItem lines="none">
        <ValidatedInput label="Name"
          name="name"
          value={name}
          setValue={setName}
          valid={nameValid}
          errorText="Please enter your first and last name"
          required
          autofocus/>
      </IonItem>
      <IonItem lines="none">
        <ValidatedInput label="Email"
          type="email"
          name="email"
          value={email}
          setValue={setEmail}
          valid={emailValid}
          errorText="Enter a valid email"
          required/>
      </IonItem>
      <PasswordInput label="Password"
        name="password"
        value={password}
        setValue={setPassword}
        valid={passwordValid}
        errorText="Passwords must be at least 8 characters long"
        required/>
      <IonCheckbox
        labelPlacement="end"
        required
        className="ion-padding-vertical"
        checked={agree}
        onIonChange={(e) => setAgree(e.target.checked)}
      >
        I agree to the <TermsLink/>
      </IonCheckbox>

      <IonButton type="submit" expand="block" disabled={!valid || loading}>
        {loading ? <IonSpinner name="dots"/> : "Create Account"}
      </IonButton>
    </form>
  );
};

export default SignUpForm;
